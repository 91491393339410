/*eslint-disable*/
import React, {useEffect, useState} from "react";

import IndexDropdown from "components/Dropdowns/IndexDropdown";

const menuVisibility = () => {
  return (!sessionStorage.getItem("loggedin") ? "relative flex w-full flex-wrap items-stretch hidden" : "relative flex w-full flex-wrap items-stretch ")
}
export default function MenuNavbar() {

  return (
    <>
      {/* Navbar */}
        <div className="w-full mx-auto items-center flex justify-between md:flex-nowrap flex-wrap md:px-10 pt-20 bg-ot menubar menubar-border">
          {/* Form */}
          <form className="md:flex hidden flex-row flex-wrap items-center mr-3">
            <div className="relative flex w-full flex-wrap items-stretch">
              <IndexDropdown className="text-white" text="Support" />
            </div>
          </form>
        </div>
      {/* End Navbar */}
    </>
  );
}
