import React, { useState, useRef } from 'react';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
// components

import Navbar from "components/Navbars/IndexNavbar.js";
import MenuNavbar from "components/Navbars/MenuNavbar.js";
import Footer from "components/Footers/Footer.js";

import axios from "axios";
import { auto } from '@popperjs/core';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import 'bootstrap/dist/css/bootstrap.min.css';

var headers = {
  'Access-Control-Allow-Origin': '*',
  'Content-Type': 'application/json',
};

const OTJourney = () => {
  const url = "http://localhost:3002/api/v1.0.0/support/otjourney?limit=50&offset=0";
  const detailCellStyle = {'padding-left': '3px', 'padding-right': '3px'};
  
  const [refreshingMsgClassName, setRefreshingMsgClassName] = useState("refreshing-msg-hidden")

  const [executionDate, setExecutionDate] = useState("");
  const [inputS3Filename, setInputS3Filename] = useState("");
  const [outputS3Filename, setOutputS3Filename] = useState("");
  const [audienceTableName, setAudienceTableName] = useState("");
  const [hasRejectedRows, setHasRejectedRows] = useState("");
  const [numberOfLeads, setNumberOfLeads] = useState("");
  const [numberOfLeadsAddedToACS, setNumberOfLeadsAddedToACS] = useState("");
  const [numberOfMembers, setNumberOfMembers] = useState("");
  const [numberOfMembersAddedToACS, setNumberOfMembersAddedToACS] = useState("");
  const [statusMessage, setStatusMessage] = useState("");
  const [successful, setSuccessful] = useState("");
  const [uploadedVerified, setUploadedVerified] = useState("");

  const [data, setData] = useState(0);

  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (o) => {
    setInputS3Filename(o.data.InputS3Filename);
    setExecutionDate(o.data.ExecutionDate);
    setOutputS3Filename(o.data.OutputS3Filename);
    setAudienceTableName(o.data.AudienceTableName);
    setHasRejectedRows(o.data.HasRejectedRows);
    setNumberOfLeads(o.data.NumberOfLeads);
    setNumberOfLeadsAddedToACS(o.data.NumberOfLeadsAddedToACS);
    setNumberOfMembers(o.data.NumberOfMembers);
    setNumberOfMembersAddedToACS(o.data.NumberOfMembersAddedToACS);
    setStatusMessage(o.data.StatusMessage);
    setSuccessful(o.data.Successful);
    setUploadedVerified(o.data.UploadedVerified);
  
    setShow(true);
  }

  const retrieveData = () => {
    axios.get(url, headers)
      .then(function (response) {
        if (response.data.success) {
          setData(response.data.data);
          setRefreshingMsgClassName("refreshing-msg-hidden");
        }
      })
      .catch(function (error) {
        console.log(error);
        setRefreshingMsgClassName("refreshing-msg-hidden");
      });
  }

  const onGridReady = (params) => {
    console.log("On Grid Ready");

    setGridApi(params.api);
    setGridColumnApi(params.columnApi);

    retrieveData();
  };

  const onBtnExport = () => {
    var params = {
      columnKeys: ['ExecutedDate', 'InputS3Filename',  'UploadedVerified', 'Successful', 'NumberOfMembers', 'NumberOfLeads', 'NumberOfMembersAddedToACS', 'NumberOfLeadsAddedToACS'],
      allColumns: false,
      fileName: 'otjourney_export.csv'
    }

    gridApi.exportDataAsCsv(params);
  };  
  
  const refreshButtonClicked  = (e) => {
    setRefreshingMsgClassName("refreshing-msg");
    retrieveData();
  }

  const buttonCellRenderer = params => {
    // put the value in bold
    return '<i class="fas fa-search"></i>';
  };

  const dateFilterParams = {
    // provide comparator function
    comparator: (filterLocalDateAtMidnight, cellValue) => {
        const dateAsString = cellValue;

        if (dateAsString == null) {
            return 0;
        }

        const dateOnlyParts = dateAsString.split(" ");
        const dateOnly = dateOnlyParts[0];

        // In the example application, dates are stored as dd/mm/yyyy
        // We create a Date object for comparison against the filter date
        const dateParts = dateOnly.split('-');
        const day = Number(dateParts[2]);
        const month = Number(dateParts[1]) - 1;
        const year = Number(dateParts[0]);
        const cellDate = new Date(year, month, day);

        // Now that both parameters are Date objects, we can compare
        if (cellDate < filterLocalDateAtMidnight) {
            return -1;
        } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
        }
        return 0;
    }
  };

  return (
    <>
      <Navbar transparent />
      <MenuNavbar />        

      <main>
        <p className="bg-blueGray-200 text-center w-full text-3xl pt-6 pb-6">
          OTJourney Execution History
        </p>
        <section className="relative w-full h-full py-2 min-h-screen bg-blueGray-200 ml-auto mr-auto">


          <div style={{ width: '80%', height: '600px', marginLeft: auto, marginRight: auto }}>
          <Button className="mb-3 mr-3" variant="primary" onClick={refreshButtonClicked} >Refresh</Button>
          <Button className="mb-3 mr-3" variant="secondary" onClick={onBtnExport} >Download</Button>
          
            <div className={refreshingMsgClassName} >Refreshing....</div>
            <div style={{ height: '600px', boxSizing: 'border-box' }}>
              <div
                id="myGrid"
                style={{
                  height: '100%',
                  width: '100%',
                }}
                className="ag-theme-alpine"
              >
                <AgGridReact
                  defaultColDef={{
                    editable: false,
                    sortable: true,
                    flex: 0,
                    width: 600,
                    filter: true,
                    floatingFilter: true,
                    resizable: true,
                  }}
                  style={{ width: '850px', height: '600px' }}
                  onGridReady={onGridReady}
                  rowData={data}
                >
                  <AgGridColumn field="" width={30} filter={false} cellRenderer={buttonCellRenderer} onCellClicked={handleShow} cellStyle={detailCellStyle}></AgGridColumn>
                  <AgGridColumn field="ExecutionDate" width={230} filter="agDateColumnFilter" inRangeInclusive={true} filterParams={dateFilterParams}></AgGridColumn>
                  <AgGridColumn field="InputS3Filename" width={600} ></AgGridColumn>
                  <AgGridColumn field="UploadedVerified" width={170}></AgGridColumn>
                  <AgGridColumn field="Successful" width={170}></AgGridColumn>
                  <AgGridColumn field="NumberOfMembers" filter={false} headerName="# of Members" width={140}></AgGridColumn>
                  <AgGridColumn field="NumberOfLeads" filter={false} headerName="# of Leads" width={140}></AgGridColumn>
                </AgGridReact>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </main>

      <Modal show={show ? true : false} onHide={handleClose} fullscreen={true}>
        <Modal.Header closeButton>
          <Modal.Title>OTJourney Execution Details</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <form>
          <div class="mt-5">
                <div class="execution-details top-group-label bg-lightBlue-200"><label class="text-label">Execution Date: </label></div> 
                <div class="float-left ml-2 text-value top-group-value">{executionDate}</div>
                <div class="float-clear" />               
                
                <div class="execution-details bottom-group-label bg-lightBlue-200"><label class="text-label">Input S3 Filename: </label></div> 
                <div class="float-left ml-2 text-value">{inputS3Filename}</div>
                <div class="float-clear" />               

                <div class="execution-details top-group-label bg-lightBlue-200 mt-5"><label class="text-label">Output S3 Filename: </label></div> 
                <div class="float-left ml-2 text-value top-group-value mt-5">{outputS3Filename}</div>
                <div class="float-clear" />               
                
                <div class="execution-details bottom-group-label bg-lightBlue-200"><label class="text-label">Audience Table Name: </label></div>
                <div class="float-left ml-2 text-value">{audienceTableName}</div>
                <div class="float-clear" />               

                <div class="execution-details top-group-label bg-lightBlue-200 mt-5"><label class="text-label">Has Rejected Rows: </label></div>
                <div class="float-left ml-2 mt-5 text-value top-group-value">{hasRejectedRows}</div>
                <div class="float-clear" />               
                
                <div class="execution-details middle-group-label bg-lightBlue-200"><label class="text-label">Number Of Leads: </label></div>
                <div class="float-left ml-2 text-value">{numberOfLeads}</div>
                <div class="float-clear" />               
                
                <div class="execution-details middle-group-label bg-lightBlue-200"><label class="text-label">Number of Leads Added to ACS: </label></div>
                <div class="float-left ml-2 text-value">{numberOfLeadsAddedToACS}</div>
                <div class="float-clear" />               
                
                <div class="execution-details middle-group-label bg-lightBlue-200"><label class="text-label">Number of Members: </label></div>
                <div class="float-left ml-2 text-value">{numberOfMembers}</div>
                <div class="float-clear" />               
                
                <div class="execution-details middle-group-label bg-lightBlue-200"><label class="text-label">Number of Members Added to ACS: </label></div>
                <div class="float-left ml-2 text-value">{numberOfMembersAddedToACS}</div>
                <div class="float-clear" />               
                
                <div class="execution-details middle-group-label bg-lightBlue-200"><label class="text-label">Status Message: </label></div>
                <div class="float-left ml-2 text-value">{statusMessage}</div>
                <div class="float-clear" />               
                
                <div class="execution-details middle-group-label bg-lightBlue-200"><label class="text-label">Successful: </label></div>
                <div class="float-left ml-2 text-value">{successful}</div>
                <div class="float-clear" />               
                
                <div class="execution-details bottom-group-label bg-lightBlue-200"><label class="text-label">Upload Verified: </label></div>
                <div class="float-left ml-2 text-value">{uploadedVerified}</div>
                <div class="float-clear" />               

          </div>
          </form>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>Close</Button>
        </Modal.Footer>
     </Modal>

    </>
  );
}

export default OTJourney;
