import React from "react";
import { createPopper } from "@popperjs/core";

require('node-sessionstorage');

const gotoLogin = (e) =>{
  e.preventDefault();
  window.location.href = "/auth/login";
}

const isLoggedIn = () => {
    return (sessionStorage.getItem('loggedin') === "true");
}

const loggedInName = () => {
  return sessionStorage.getItem("firstname") + " " + sessionStorage.getItem("lastname") ;
}

const logout = (e) => {
  e.preventDefault();

  sessionStorage.setItem("loggedin", false);
  sessionStorage.setItem("userid", "");
  sessionStorage.setItem("firstname", "");
  sessionStorage.setItem("lastname", "");

  window.location.href = "/";
}

const UserDropdown = () => {
  // dropdown props
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const btnDropdownRef = React.createRef();
  const popoverDropdownRef = React.createRef();
  const openDropdownPopover = () => {
    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "bottom-start",
    });
    setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };
  return (
    <>
      <a
        className="text-blueGray-500 block"
        href="#pablo"
        ref={btnDropdownRef}
        onClick={(e) => {
          e.preventDefault();
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
        }}
      >
        <div className="items-center flex">
          <span className="w-12 h-12 text-sm text-white bg-blueGray-200 inline-flex items-center justify-center rounded-full">
            <img
              alt="..."
              className="w-full rounded-full align-middle border-none shadow-lg"
              src={require("assets/img/team-1-800x800.jpg").default}
            />
          </span>
        </div>
      </a>
      <div
        ref={popoverDropdownRef}
        className={
          (dropdownPopoverShow ? "block " : "hidden ") +
          "bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
        }
      >
          { isLoggedIn()? 
          <div className="bg-black text-base z-50 py-2 text-left p-4 m-2 px-4 block text-white text-bold thick-bottom-border-black">Logged in as {loggedInName()}</div>
            :
            <div></div>
          }


          { !isLoggedIn()?
              <a
                href="#pablo"
                className={
                  "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
                }
                onClick={(e) => gotoLogin(e) }
              >
                Login
              </a>
          :
              <a
              href="#pablo"
              className={
                "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
              }
              onClick={(e) => logout(e) }
            >
              Logout
            </a>
        }
      </div>
    </>
  );
};

export default UserDropdown;
