/*eslint-disable*/
import React from "react";

import UserDropdown from "components/Dropdowns/UserDropdown.js";
import logo from "assets/img/otf-header-logo-white.svg"
import IndexDropdown from "components/Dropdowns/IndexDropdown";

export default function Navbar() {
  return (
    <>
      {/* Navbar */}
      <nav className="absolute top-0 left-0 w-full z-10 md:flex-row md:flex-nowrap md:justify-start flex items-center p-3 bg-black">
        <div className="w-full mx-auto items-center flex justify-between md:flex-nowrap flex-wrap">
          {/* Brand */}
          <a
            className="text-white text-sm uppercase hidden lg:inline-block font-semibold"
            href="/">
              <img src={logo} className="logo-image" alt="logo" height="50" />
            
          </a>
          {/* Form */}

          <form className="md:flex hidden flex-row flex-wrap items-center lg:mr-auto mr-3" style={{"marginLeft": "-160px"}} >
            <div className="relative flex w-full flex-wrap items-stretch">
              <span className="z-10 h-80 leading-snug font-bold text-center text-white bg-transparent rounded text-base text-4xl justify-center w-22 pl-3 ">
                Database Portal
              </span>
            </div>
          </form>


          {/* User */}
          <ul className="flex-col md:flex-row list-none items-center hidden md:flex mb-0 ">
            <UserDropdown />
          </ul>
        </div>

      </nav>
      {/* End Navbar */}
    </>
  );
}
